import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App6';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://mnomj3xg61.execute-api.us-east-2.amazonaws.com/dev/graphql',
    fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache()
});

ReactDOM.render(
      <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);


reportWebVitals();
