import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import ShareButton from "./sharebutton";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "white",
    borderRadius: "15px",
      fontFamily: "Bebas Neue",
  },
};


function Mymodal(props) {
  let subtitle;
  let wins = props.score[1]
  let loss = props.score[2]
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = 'chartreuse';
  }

  function closeModal() {
        props.resetpage()
    setIsOpen(false);
  }

  return (
    <div id='appelement'>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
		ariaHideApp={false}
      >
        <h2 id='sharebox' >MovieQuiz Share</h2>
        <div id='modalclosebutton' onClick={closeModal}>X</div>
        <div>Would you like to Share your score?</div>
        <ShareButton score={props.score} closeButton={closeModal}/>
      </Modal>
    </div>
  );
}

export default Mymodal;
