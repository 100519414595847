import React, {useState} from "react";
import Error from "./errorlogging";



function ShareButton(props) {
   var [senderror, setsenderror] = useState(false);
    var [errormsg, seterrormsg] = useState();

    if (userwins >0 ) {var userwins = props.score[0] > 1 ? " wins" : " win" } else {userwins = " wins"}
    if (userloss >0 ) {var userloss = props.score[1] > 1 ? " losses" : " loss"} else {userloss=" losses"}

  const shareData = {
    title: "MovieQuiz Score",
    text: "Check out my MovieQuiz Score: " + props.score[0] +  userwins + " , and " + props.score[1] + " " + userloss,
      url: "https://www.movietaglinegame.com"
  };

  const btn = document.querySelector("button");
  const resultPara = document.querySelector(".result");

  const handleSubmit = async (e) => {
    try {
      await navigator.share(shareData)
          .then(props.closeButton)
          .catch(error => {
              setsenderror(true)
                seterrormsg(error)
            });
      resultPara.textContent = "MovieQuiz shared successfully";
      setTimeout(()=>{
        resultPara.textContent = "";
      },1000)

    } catch (err) {
      setsenderror(true)
      seterrormsg(err)
    }
  };

  return (
    <div id={'sharebutton'}>
      <button id='sharebutton' onClick={() => handleSubmit()}>Share</button>
      <div className={"result"} />
      {senderror && <Error error={errormsg} />}
    </div>
  );
}

export default ShareButton;
