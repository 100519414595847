import React, { Component, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Modal from "./modal";

const PUT_SIGNUP = gql`
  query ($username: String!, $wins: Int!, $loss: Int!) {
    putleadership(username: $username, wins: $wins, loss: $loss)
  }
`;

function FeedWithData(props) {
  var [modalopen, setmodalopen] = useState(false);
  const { loading, error, data } = useQuery(PUT_SIGNUP, {
    variables: {
      username: props.username,
      wins: +props.wins,
      loss: +props.loss,
    },
  });
  if (error) {
    alert("That username is taken" );
    props.posttoleaderboard();
  }
  if (loading) return <div>Loading...</div>;
  if (data) {
    setTimeout(() => {
        var ischrome =
          navigator.userAgent.indexOf("Chrome") != -1 ? true : false;
        if (!ischrome) {
          console.log("Not Chrome")
            // props.closemobilemodal()
          setmodalopen(true);

        } else {
            props.posttoleaderboard();
            props.resetpage();
        }
      return "Done";
    }, 1500);
  }

  return (
    <div>{data && data.putleadership}
      {modalopen &&
        <Modal isopen={true} score={[props.wins, props.loss]} resetpage={props.resetpage} />
      }
    </div>
  );
}

export default FeedWithData;
