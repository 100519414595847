import React, { useState, useEffect } from "react";
import FeedWithData from "./QueryOnly";
import Modal from "./mobilemodal";
import Swal from 'sweetalert2'

const ScoreBoard = (props) => {
  const [mobilescore, setmobilescore] = useState("");
  var [stateusername, setstateusername] = useState();
  var [posttoleaderboard, setposttoleaderboard] = useState(false);
  var [mobilemodalopen, setmobilemodalopen] = useState(false);

  var myinput = document.getElementById("checkid2");


  function reputvalue() {
    // myinput = checkid2.value = "Enter a username";
  }

  function clearinput() {
    myinput.value = ""
  }

    function resetscoreboard() {
      props.resetpage()
      setmobilemodalopen(false)
      setposttoleaderboard(false)

  }

  function resetposttoleaderboard() {
    myinput.value= ""
    setposttoleaderboard(false);
  }


  const handleSubmitweb = async (e) => {
    setposttoleaderboard(false)
    e.preventDefault();
    var entereduser = e.target.username.value;

    if (props.wins != 0 || props.loss != 0 && entereduser.length > 1) {
      setposttoleaderboard(true)

      setTimeout(() => {
        var ischrome = (navigator.userAgent.indexOf("Chrome") != -1 ) ? true : false
      }, 1000);
    } else {
      alert("You can't record a zero score");
    }
  };

  var handleSubmitmobile = async (e) => {

      if (props.wins != 0 || props.loss != 0 ) {
            setmobilemodalopen(true)
        // setTimeout(() => {
        //
        //   var ischrome = (navigator.userAgent.indexOf("Chrome") != -1) ? true : false
        //   if (!ischrome) {
        //     setmobilemodalopen(true)
        //   }
        // }, 1000);
      } else {
          Swal.fire({
  title: 'Error!',
  text: 'You can\'t record a zero score',
  icon: 'error',
  confirmButtonText: 'Cool'
})
      }
  }


  return (
    <div id="scorebox">
      <div id={"scoreheader"}>Score</div>
      <div>
        Correct:<span id={"userwins"}>{props.wins}</span>
      </div>
      <div>
        Wrong:<span id={"userloss"}>{props.loss}</span>
      </div>
      <br />
      <div id={"mobilemodal"} onClick={(e) => handleSubmitmobile(e)}>
        Record Score?
      </div>
            {mobilemodalopen &&
        <Modal isopen={true} wins={props.wins} loss={props.loss} resetpage={props.resetpage} posttoleaderboard={resetposttoleaderboard} resetpage={resetscoreboard}  />
      }
      <div id={"recordscore"}>
        <div>Want to record your score?</div>
        <form
          onSubmit={(e) => handleSubmitweb(e)}
          className="subscribe-form wow animated animate__animated animate__fadeInUp"
        >
          <input
            type="username"
            name="username"
            maxLength={12}
            className="subscribe form-control"
            placeholder="Username"
            onFocus={() => clearinput()}
            onBlur={() => reputvalue()}
            defaultValue={"Enter a username"}
            id={"checkid2"}
          />
          <input type={"submit"} />
          {posttoleaderboard && (
            <FeedWithData wins={props.wins} loss={props.loss} posttoleaderboard={resetposttoleaderboard} username={document.getElementById("checkid2").value} resetpage={resetscoreboard}  />
          )}
        </form>
        <div className={"result"} />

      </div>

    </div>
  );
};

export default ScoreBoard;
