import React, { useState, useEffect } from "react";
import "./App.css";
const axios = require("axios").default;
import ScoreBox from "./scoreboard";
import GetLeaders from "./getleaders";
import Tmdblogo from "./tmdblogo.svg";
import Error from "./errorlogging";
import Swal from "sweetalert2";

function App() {
  var [movie, setMovie] = useState({});
  var [taglines, setTaglines] = useState([]);
  var [questionNumber, setquestionNumber] = useState();
  var [winners, setwinners] = useState(0);
  var [losers, setlosers] = useState(0);
  var [newleaderboard, setnewleaderboard] = useState("");
  var [senderror, setsenderror] = useState(false);
  var [errormsg, seterrormsg] = useState();
  var shouldtaglinesberun = true;
  var shouldgetmovierun = true;
  var counter = 0;
  var answercounter = 0;

  async function getTaglines() {
    var needtorun = 4 - counter;
    for (var i = 0; i < needtorun; i++) {
      let myrandom = Math.floor(Math.random() * 1000);

      await axios
        .get(
          "https://api.themoviedb.org/3/movie/" +
            myrandom +
            "?api_key=" +
            process.env.REACT_APP_TMDB_KEY
        )
        .then(function (response) {
          if (response.data.tagline.length > 0) {
            if (typeof response.data.tagline == "string") {
              setTaglines((taglines) => [...taglines, response.data.tagline]);
              counter++;
            }
          }
        })
        .catch(function (error) {
          seterrormsg(error);
        });
    }
  }

  if (counter > 4) {
    shouldtaglinesberun = false;
  }

  useEffect(() => {
    if (movie) {
      getTaglines();
    }
  }, [shouldtaglinesberun]);

  async function getMovie() {
    let myrandom = Math.floor(Math.random() * 1000);

    await axios
      .get(
        "https://api.themoviedb.org/3/movie/" +
          myrandom +
          "?api_key=bab5ef6338f133cddaaa138e7508a161"
      )
      .then(function (response) {
        if (response.data.tagline.length > 0) {
          setMovie(response);
          if (typeof response.data.tagline == "string") {
            setTaglines((taglines) => [...taglines, response.data.tagline]);
            answercounter++;
          }
        } else {
          getMovie();
        }
      })
      .catch(function (error) {
        seterrormsg(error);
        getMovie();
      });
  }

  function resetpage() {
    setTaglines([]);
    getMovie();
    getTaglines();
    setwinners(0);
    setlosers(0);
    setnewleaderboard();
  }

  function rerun() {
    setTaglines([]);
    getMovie();
    getTaglines();
  }

  shouldgetmovierun = answercounter > 0 ? false : true;

  useEffect(() => getMovie(), [shouldgetmovierun]);

  function clickbox(e) {
    const myanswer = document.getElementById(e.target.id).textContent;
    if (movie.data != undefined) {
      if (myanswer == movie.data.tagline) {
        setwinners(winners + 1);
        // alert("your answer is correct!");
        Swal.fire({
          title: "CORRECT!",
          text: "your answer is correct!",
          icon: "success",
          confirmButtonText: "Cool",
        });
        rerun();
      } else {
        // alert("WRONG.  The correct answer is " + movie.data.tagline);
        Swal.fire({
          title: "WRONG!",
          text: "WRONG.  The correct answer is " + movie.data.tagline,
          icon: "error",
          confirmButtonText: "Cool",
        });
        setlosers((losers) => losers + 1);
        rerun();
      }
    } else {
      rerun();
    }
  }

  function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  shuffleArray(taglines);

  var myreturn = movie.data ? (
    <div>
      <span id={"movietitle"}>{movie.data && movie.data.title}</span>
      <div>
        <img
          id={"movieimage"}
          src={`https://image.tmdb.org/t/p/w500/` + movie.data.poster_path}
        />
      </div>
    </div>
  ) : (
    <div>LOADING....</div>
  );
  var myanswerchoices = taglines ? (
    <ul id={"answerlist"}>
      {taglines &&
        taglines.map((mytag, i) => {
          return (
            <li
              key={"tagline" + i}
              className={"mylistitem"}
              name={"tagline" + i}
              id={"tagline" + i}
              onClick={(e) => clickbox(e)}
            >
              {mytag}
            </li>
          );
        })}
    </ul>
  ) : (
    <div>WAITING...</div>
  );

  return (
    <div className="App">
      <div className="App-header">
        <div
          className="fb-like"
          data-href="https://developers.facebook.com/docs/plugins/"
          data-width=""
          data-layout="button"
          data-action="like"
          data-size="small"
          data-share="true"
        ></div>
        <div className="App-header animated animate__animated animate__fadeInRight">
          <h3 id={"movieheader"}>Movie Quiz</h3>
          {myreturn}
          <h3>What is the tagline for this movie?</h3>
          {myanswerchoices}
        </div>
        <ScoreBox
          wins={winners}
          loss={losers}
          resetpage={resetpage}
          rerun={rerun}
        />
        <div id="leaderboard">
          <h2>LeaderBoard</h2>
          <GetLeaders mystatevar={newleaderboard} />
        </div>
        <div id={"disclosure"}>
          This product uses the TMDB API but is not endorsed or certified by
          TMDB
        </div>
        <a href={"https://www.themoviedb.org/"}>
          <img id="tmdblogo" src={Tmdblogo} alt="TMDB Logo" />
        </a>
      </div>
      {senderror && <Error error={errormsg} />}
    </div>
  );
}

export default App;
