import React, { Component, useState } from "react";
import { gql, useQuery } from "@apollo/client";



const PUT_ERROR = gql`
query ($username: String!, $error: String!) {
    logerrors(username: $username error: $error ) 
}
`;


function LogError(props) {
  const { loading, error, data } = useQuery(PUT_ERROR, {
    variables: {
      username: "klowny",
      error: JSON.stringify(props.error),
    },
  });
  if (error) return <div>Error {JSON.stringify(error)}</div>;
  if (loading) return <div>Loading...</div>;

  return null;
}

export default LogError;
