import { gql, useQuery } from "@apollo/client";
import Error from "./errorlogging";
import React from "react";

const PUT_SIGNUP = gql`
  query {
    scanleadership(username: "Klowny") {
      wins
      loss
      id
      username
    }
  }
`;

function GetLeaders() {
  const { loading, error, data, refetch } = useQuery(PUT_SIGNUP);
  if (error) return <Error error={error}/>;
  if (loading) return <div>Loading...</div>;
  var myarray = data.scanleadership;
  var arrayForSort = [...myarray];
  arrayForSort.sort((a, b) => {
    return b.wins - a.wins;
  });
  refetch();

  var myarrayreturn = arrayForSort.map((elem) => {
    return (
      <div id="leaderscss" key={elem.id}>
        <div>{elem.username}</div>
        <div>{elem.wins}</div>
        <div>{elem.loss}</div>
      </div>
    );
  });

  return (
    <div className="animated animate__animated animate__fadeInLeft">
      <div id={"leaderheader"}>
        <div>Leaders</div>
        <div>Wins</div>
        <div>Losses</div>
      </div>
      <div id={'leaderscroll'}>{myarrayreturn}</div>
    </div>
  );
}

export default GetLeaders;
