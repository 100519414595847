import React, { useState } from "react";
import Modal from "react-modal";
import FeedWithData from "./QueryOnly";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "chartreuse",
    fontFamily: "Bebas Neue",
  },
};

function MobileModal(props) {
  const [posttoleaderboard, setposttoleaderboard] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);

var myinput2 = document.getElementById("mobileusername");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    props.resetpage();
    setIsOpen(false);
  }

    function resetmobileposttoleaderboard() {
    myinput2.value= ""
    setposttoleaderboard(false);
  }

    var handleSubmitmobile = async (e) => {
     e.preventDefault()
    setposttoleaderboard(true)

  }

    function clearinput() {
    // var myinput2 = document.getElementById("mobileusername");
    document.getElementById("mobileusername").value = ""
  }

  return (
    <div id="appelement">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onFocus={() => clearinput()}
        style={customStyles}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <div >
          <div>Want to record your score?</div>
          <form
            onSubmit={(e) => handleSubmitmobile(e)}
            className="subscribe-form wow animated animate__animated animate__fadeInUp"
          >
            <input
              type="username"
              name="username"
              maxLength={12}
              className="subscribe form-control"
              placeholder="Username"
              onFocus={() => clearinput()}
              defaultValue={"Enter a username"}
              id={"mobileusername"}
            />
            <input type={"submit"} />
            {posttoleaderboard && (
              <FeedWithData
                wins={props.wins}
                loss={props.loss}
                posttoleaderboard={resetmobileposttoleaderboard}
                username={myinput2.value}
                resetpage={props.resetpage}
                closemobilemodal={closeModal}
                resetusername={props.resetusername}
              />
            )}
          </form>
          <div className={"result"} />
        </div>
      </Modal>
    </div>
  );
}

export default MobileModal;
